import { z } from 'zod'
import { datetimeLocal } from '@forgd/shared/utils/zod'
import { EngagementOptionArray } from '@forgd/supabase'
import { GetLiquidityBreakdownsByExchangeResult } from '../liquidity-monitoring'

export const PostMarketMakerSchema = z.object({
  name: z.string(),
  ticker: z.string(),
  website: z.string(),
  dateOfTGE: z.string().date(),
  tokenListed: z.boolean(),
  workingWithMarketMaker: z.boolean(),
  exploreSelfServiceSolution: z.boolean(),
  additional: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
})

export const PostMarketMakerResult = z.object({
  id: z.string(),
  projectId: z.string(),
  name: z.string(),
  ticker: z.string().nullable(),
  website: z.string(),
  dateOfTGE: z.string().date(),
  tokenListed: z.boolean(),
  workingWithMarketMaker: z.boolean(),
  exploreSelfServiceSolution: z.boolean(),
  additional: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
  createdAt: datetimeLocal,
  updatedAt: datetimeLocal,
  deletedAt: datetimeLocal.nullable(),
})

export const GetActiveMarketMakerDetailsQuery = z.object({
  mmId: z.string({ required_error: 'market maker id is required' }),
  date: z.string().refine(
    (value) => {
      const date = new Date(value)
      return !isNaN(date.getTime())
    },
    {
      message: 'Invalid date format. Must be YYYY-MM-DD',
    },
  ),
})

export const GetActiveMarketMakerDetails = z.object({
  type: z.enum(EngagementOptionArray),
  totalDaysTracked: z.number(),
  activeCex: z.number(),
  activeDex: z.number(),
  avgFillVolume: z.number(),
  avgDepth: z.number(),
  avgSpread: z.number(),
  avgVolumeBasedLoanUtil: z.number(),
  avgLiquidityBasedLoanUtil: z.number(),
  percentageOfTotalVolume: z.number(),
  percentageOfTotalLiquidity: z.number(),
  totalLiquidity: z.number(),
  breakdownByExchange: GetLiquidityBreakdownsByExchangeResult,
  totalVolume: z.number(),
  loanSize: z.number(),
})

export const GetActiveMarketMakerDetailsResult = z.union([GetActiveMarketMakerDetails, z.null()])

export type ActiveMarketMakerDetails = z.infer<typeof GetActiveMarketMakerDetailsResult>
