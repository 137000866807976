import { initContract } from '@ts-rest/core'
import { z } from 'zod'
import { LeaderboardMarketMakerExchangesResult, LeaderboardMarketMakerResult, MarketMakerPathParam } from '../types'

const c = initContract()

const baseUrl = '/liquidity-market-maker'

export const liquidityMarketMakerContract = c.router({
  getEngagedProjects: {
    method: 'GET',
    path: `${baseUrl}/engaged-projects`,
    responses: {
      200: z.array(
        z.object({
          projectId: z.string().uuid(),
          projectName: z.string(),
          imageUrl: z.string().nullable(),
        }),
      ),
    },
    summary: 'Get market maker engaged projects',
  },
  getMarketMaker: {
    method: 'GET',
    path: `${baseUrl}/:marketMakerId`,
    pathParams: MarketMakerPathParam,
    responses: {
      200: LeaderboardMarketMakerResult,
    },
    summary: 'Get market maker by ID of liquidity portal.',
  },
  getMarketMakerExchanges: {
    method: 'GET',
    path: `${baseUrl}/:marketMakerId/exchanges`,
    pathParams: MarketMakerPathParam,
    responses: {
      200: z.object({
        supportedCEX: LeaderboardMarketMakerExchangesResult,
        supportedDEX: LeaderboardMarketMakerExchangesResult,
      }),
    },
    summary: 'Get exchanges of market maker by ID of liquidity portal.',
  },
})

export type LiquidityMarketMakerContractType = typeof liquidityMarketMakerContract
