import type { ParsedContent } from '@nuxt/content/types'
import { firstChildToIfDir, type NavLink } from '#core/utils/nav'

let academyNavPromise: Promise<AcademyNavGroup[]> | null = null
const academyNav = ref<AcademyNavGroup[]>([])

type MergedNav = ParsedContent & NavLink
export interface AcademyNavGroup extends MergedNav {
  children?: MergedNav[]
}
export function fetchAcademyNav() {
  academyNavPromise = academyNavPromise || new Promise((resolve) => {
    fetchContentNavigation('academy').then(data => data[0].children as AcademyNavGroup[]).then((res) => {
      academyNav.value = res
      // @ts-expect-error type hack
      resolve({ data: academyNav })
    })
  })
  return Object.assign(academyNavPromise, { data: academyNav }) as any as ({ data: Ref<AcademyNavGroup[]> } & Promise<{ data: Ref<AcademyNavGroup[]> }>)
}

export const forgdAcademyNav = computed(() => {
  if (!academyNav.value?.length) {
    return []
  }
  return [
    ...academyNav.value.map((item) => {
      return {
        ...item,
        label: item.title,
        to: item._path,
        children: (item.children || []).map(item => ({ ...item, label: item.title, to: firstChildToIfDir(item) })),
      }
    }),
    {
      label: 'Other Readings',
      icon: 'i-heroicons-newspaper',
      children: [
        { label: 'Forgd Products FAQs', to: 'https://forgd.com/faq#tools' },
        { label: 'About Forgd FAQs', to: 'https://forgd.com/faq#about' },
        { label: 'Forgd Blog', to: 'https://forgd.com/blog' },
      ],
    },
  ] satisfies NavLink[]
})
