// TODO refactor out of the core package
import { acceptHMRUpdate, defineStore } from 'pinia'

export const useTeammateAccessWall = defineStore('teammateAccessWall', () => {
  const isVisible = ref(false)

  const show = () => {
    isVisible.value = true
  }

  const hide = () => {
    isVisible.value = false
  }

  return {
    isVisible,
    show,
    hide,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTeammateAccessWall, import.meta.hot))
}
