import { z } from 'zod'
import { datetimeLocal } from '@forgd/shared/utils/zod'
import { ExchangeMcapTierArray } from '@forgd/supabase'

export enum FeeCaseType {
  Best = 'Best',
  Base = 'Base',
  Worst = 'Worst',
}
export const FeeCaseTypeArray = ['Best', 'Base', 'Worst'] as const
export const ExchangeTypeArray = ['CEX', 'DEX'] as const
export const ExchangeTypeEnum = z.enum(ExchangeTypeArray)

export const TExchange = z.object({
  id: z.string(),
  name: z.string(),
  logoUrl: z.string().nullable().optional(),
  type: ExchangeTypeEnum,
  deletedAt: datetimeLocal.nullable().optional(),
  description: z.string().nullable().optional(),
  commentary: z.string().nullable().optional(),
  rank: z.number().int().nullable().optional(),
})

export const TRankedExchange = z.object({
  id: z.string(),
  name: z.string(),
  image: z.string(),
  type: ExchangeTypeEnum,
  rank: z.number().int(),
  description: z.string(),
  introCommentary: z.string().nullable(),
  pricePerformanceRating: z.number().nullable(),
  tradingKpisRating: z.number().nullable(),
  costAndAccessibilityRating: z.number().nullable(),
})

export type RankedExchange = z.infer<typeof TRankedExchange>

export const TExchangesRankedResponse = z.array(TRankedExchange)

export const TPreferences = z.object({
  tokenSupply: z.number().int(),
  tokenFDV: z.number().int(),
  tgeDate: datetimeLocal.nullable().optional(),
})
export type Preferences = z.infer<typeof TPreferences>

export const TListingFeesRequest = z.object({
  tokenFDV: z.string().transform(Number),
  exchanges: z.union([z.string().transform((val) => [val]), z.array(z.string())]).default([]),
  feeCaseType: z.enum(FeeCaseTypeArray),
})

export type ListingFeesRequest = z.infer<typeof TListingFeesRequest>

export const feeDenominationItem = z.object({
  stablecoin: z.number().int(),
  token: z.number().int(),
  tokenSupplyPercentage: z.number(),
  totalUSD: z.number().int(),
})

export const TListingFee = z.object({
  feeBreakdownByExchange: z.array(
    z.object({
      exchange: z
        .object({
          id: z.string(),
          name: z.string().nullable().optional(),
          rank: z.number().int().nullable().optional(),
        })
        .nullable()
        .optional(),
      nonRefundableFees: feeDenominationItem,
      nonRefundablePercentage: z.number(),
      refundableFees: feeDenominationItem,
      refundablePercentage: z.number(),
      total: feeDenominationItem,
    }),
  ),
  feeSummaryByCategory: z.object({
    listingAndIntegrationFee: z.number().int(),
    marketingBudget: z.number().int(),
    securityDeposit: z.number().int(),
    marketMakerDeposit: z.number().int(),
  }),
})

export const TListingFeesResponse = z.object({
  worst: TListingFee,
  base: TListingFee,
  best: TListingFee,
})

export const TExchangeCostBreakdown = z.object({
  id: z.string(),
  name: z.string(),
  logoUrl: z.string().nullable().optional(),
  rank: z.number().int(),
  dueDiligenceDescription: z.string().nullable().optional(),
  hasListingFees: z.boolean(),
  hasSecurityDeposit: z.boolean(),
  estimatedListingFees: z.number(),
})
export type ExchangeCostBreakdown = z.infer<typeof TExchangeCostBreakdown>

export const TCostBreakdownByExchangeResponse = z.array(TExchangeCostBreakdown)

export const TExchangeAvgPostTgePopPerformance = z.object({
  name: z.string(),
  pop: z.number(),
})

export const TExchangeAvgPostTgePopPerformanceResponse = z.array(TExchangeAvgPostTgePopPerformance)

export type ExchangeAvgPostTgePopPerformance = z.infer<typeof TExchangeAvgPostTgePopPerformance>

export const TExchangeProfile = z.object({
  id: z.string().trim().min(1),
  name: z.string().trim().min(1),
  logoUrl: z.string().trim().min(1).nullable(),
  rank: z.number().int().positive(),
  commentary: z.string().nullable(),
  pricePerformanceRating: z.number().int().min(1).max(5),
  tradingKpisRating: z.number().int().min(1).max(5),
  costAndAccessibilityRating: z.number().int().min(1).max(5),
  estimatedFees: z.number().nonnegative(),
  demographics: z.object({
    northAmerica: z.number().min(0).max(100),
    southAmerica: z.number().min(0).max(100),
    europe: z.number().min(0).max(100),
    middleEast: z.number().min(0).max(100),
    africa: z.number().min(0).max(100),
    oceania: z.number().min(0).max(100),
    asia: z.number().min(0).max(100),
  }),
})

export type ExchangeProfile = z.infer<typeof TExchangeProfile>

export const TExchangeTradingStatistics = z.object({
  name: z.string(),
  logoUrl: z.string().nullable(),
  mCapTier: z.enum(ExchangeMcapTierArray),
  avg24hVol: z.number(),
  avgAskDepthPos2: z.number(),
  avgBigDepthNeg2: z.number(),
  avgDepth2: z.number(),
  avgSpread: z.number(),
  avg24hVolRank: z.number(),
  avgAskDepthPos2Rank: z.number(),
  avgBigDepthNeg2Rank: z.number(),
  avgDepth2Rank: z.number(),
  avgSpreadRank: z.number(),
})

export type ExchangeTradingStatistics = z.infer<typeof TExchangeTradingStatistics>

export const TExchangeKpiChartRecord = z.object({
  index: z.number().int().nonnegative(),
  kpi: z.number(),
  label: z.string(),
})

export type ExchangeKpiChartRecord = z.infer<typeof TExchangeKpiChartRecord>

export const TExchangeKpiChartResponse = z.object({
  exchangeKpiChart: z.object({
    data: z.array(TExchangeKpiChartRecord),
    meta: z.object({
      xAxis: z.string(),
      xFormat: z.string(),
      labels: z.array(z.string()),
    }),
  }),
  exchangeKpiRanks: z.array(
    z.object({
      key: z.enum(ExchangeMcapTierArray),
      label: z.string().trim().min(1),
      rank: z.number().int().positive(),
    }),
  ),
})

export type ExchangeKpiChartResponse = z.infer<typeof TExchangeKpiChartResponse>

export const TExchangeRoiChartRecord = z.object({
  index: z.number().int().nonnegative(),
  roi: z.number(),
  roiChange: z.number(),
})

export type ExchangeRoiChartRecord = z.infer<typeof TExchangeRoiChartRecord>

export const TExchangeRoiChartResponse = z.object({
  exchangeRoiChart: z.object({
    data: z.array(TExchangeRoiChartRecord),
    meta: z.object({
      xAxis: z.string(),
      xFormat: z.record(z.string(), z.string().trim().min(1)),
      labels: z.array(z.string()),
    }),
  }),
  exchangeRoiRanks: z.array(
    z.object({
      key: z.string().trim().min(1),
      label: z.string().trim().min(1),
      rank: z.number().int().positive(),
    }),
  ),
})

export type ExchangeRoiChartResponse = z.infer<typeof TExchangeRoiChartResponse>

export type ListingFeesRequestParams = z.infer<typeof TListingFeesRequest>

export enum QuestionsTableFilterSelectionTypes {
  EXCHANGE = 'exchange',
  CATEGORY = 'category',
  PAGE = 'page',
}

export const TQuestionsRequestParams = z.object({
  exchangeId: z.string().optional(),
  category: z.string().optional(),
  page: z.number().optional(),
})
