import { z } from 'zod'
import { EngagementOptionArray, ExchangeTierArray } from '@forgd/supabase'

export const LeaderboardMarketMakerResult = z.object({
  id: z.string().nullish(),
  name: z.string().nullish(),
  description: z.string().nullish(),
  engagementOptions: z.array(z.enum(EngagementOptionArray)).nullish(),
  services: z.array(z.string()).nullish(), // for now no normal jsonb validation
  imageUrl: z.string().nullish(),
})

export type LeaderboardMarketMakerResultType = z.infer<typeof LeaderboardMarketMakerResult>

export const LeaderboardMarketMakerExchangeResult = z.object({
  id: z.string(),
  name: z.string(),
  logoUrl: z.string().nullish(),
  description: z.string().nullish(),
  rank: z.number().nullish(),
  tier: z.enum(ExchangeTierArray).nullish(),
  type: z.string().nullish(),
  commentary: z.string().nullish(),
})

export type LeaderboardMarketMakerExchange = z.infer<typeof LeaderboardMarketMakerExchangeResult>

export const LeaderboardMarketMakerExchangesResult = z.array(LeaderboardMarketMakerExchangeResult)
