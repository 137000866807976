export default {
  dashboard: () => import("/opt/render/project/src/apps/ui/layouts/dashboard.vue"),
  "core-dashboard": () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layouts/core-dashboard.vue"),
  auth: () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/layouts/auth.vue"),
  guest: () => import("/opt/render/project/src/libs/nuxt-forgd-layer-core/layers/auth/layouts/guest.vue"),
  home: () => import("/opt/render/project/src/apps/ui/layers/dashboard/layouts/home.vue"),
  onboarding: () => import("/opt/render/project/src/apps/ui/layers/onboarding/layouts/onboarding.vue"),
  "public-share-token": () => import("/opt/render/project/src/apps/ui/layers/token-designer/layouts/public-share-token.vue"),
  "token-designer": () => import("/opt/render/project/src/apps/ui/layers/token-designer/layouts/token-designer.vue"),
  tokenomics: () => import("/opt/render/project/src/apps/ui/layers/token-designer/layouts/tokenomics.vue"),
  "automated-market-making": () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/layouts/automated-market-making.vue"),
  "engage-a-market-maker": () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/layouts/engage-a-market-maker.vue"),
  "exchange-listing-side-menu": () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/layouts/exchange-listing-side-menu.vue"),
  "market-maker-monitoring": () => import("/opt/render/project/src/apps/ui/layers/market-maker-monitoring/layouts/market-maker-monitoring.vue")
}