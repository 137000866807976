import { defineNuxtPlugin } from '#app'
import { type AcademyNavGroup, fetchAcademyNav } from '../utils/nav'

export default defineNuxtPlugin((nuxtApp) => {
  const searchData = ref<AcademyNavGroup[]>([])
  const academyNav = fetchAcademyNav()
  // network is free, preload search
  onNuxtReady(() => {
    $fetch<AcademyNavGroup[]>('/api/academy/search.json').then((res) => {
      return res.map(link => ({
        ...link,
        key: link._path,
        label: link.title,
      }))
    }).then((res) => {
      searchData.value = res
    })
  })
  const searchGroup = computed(() => {
    return academyNav.data.value?.map((item) => {
      return {
        key: item._path,
        icon: item.icon,
        label: `Forgd Academy > ${item.title}`,
        to: item._path,
        commands: item.children?.map(c => ({
          icon: item.icon,
          label: c.title,
          // @ts-expect-error untyped
          to: c.children?.[0]._path || c._path,
        })) || [],
      }
    }) || []
  })
  const filterGroup = computed(() => {
    return searchGroup.value.map((group) => {
      group.commands = searchData.value.filter((i) => {
        // @ts-expect-error untyped
        return i._path.startsWith(group.to)
      }).map(i => ({
        ...i,
        to: i._path,
        icon: group.icon,
        label: `${group.label.split('>')[1].trim()} > ${i.title}`,
      }))
      return group
    })
  })
  nuxtApp.hooks.hook('forgd:search', async (ctx) => {
    // @ts-expect-error untyped
    ctx.nav.push(searchGroup)
    ctx.filter.push(filterGroup)
  })
})
