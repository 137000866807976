import { defineThemeConfig } from './utils/theme'

export default defineAppConfig({
  /**
   * Cmd+Click props to go to original component theme file
   * @see @nuxt/ui/dist/runtime/ui.config
   */
  ui: defineThemeConfig({
    primary: 'brand',

    popover: {
      width: '',
      popper: {
        strategy: 'absolute',
      },
      default: {
        openDelay: 300,
        closeDelay: 0,
      },
    },

    checkbox: {
      color: 'text-forgd-green-400',
    },

    button: {
      font: 'font-display font-semibold',
      variant: {
        tooltip: 'text-forgd-primary-300',
      },
      color: {
        black: {
          solid: 'bg-forgd-primary-900 text-forgd-bgd-100',
        },
      },
    },

    toggle: {
      color: 'forgd-primary-300',
    },

    notifications: {
      position: '-top-2 bottom-auto left-0',
    },

    dropdown: {},

    card: {
      rounded: 'rounded-lg',
      ring: 'ring-forgd-bgd-600',
      shadow: '',
      background: 'bg-forgd-bgd-100',
      divide: 'divide-forgd-bgd-600',
      header: {
        base: 'h-[70px] flex items-center',
      },
      body: {
        padding: 'px-0 py-0 sm:p-0',
      },
    },

    divider: {
      border: {
        base: 'border-forgd-bgd-600',
      },
    },

    textarea: {
      color: {
        white: {
          outline: 'shadow-none focus:ring-forgd-primary-300',
        },
      },
      variant: {
        outline: 'shadow-none focus:ring-forgd-primary-300',
      },
    },
  }),
})
