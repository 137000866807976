import { z } from 'zod'
import { stringToIntNumber } from '@forgd/shared/utils/zod'

export const GetKPIDataQuery = z.object({
  date: z.string().date(),
  orderBook: z.string().nullish(),
  exchange: z.string().nullish(),
  daysLookback: z
    .string()
    .optional()
    .nullable()
    .transform(stringToIntNumber)
    .refine((val) => val == null || val > 0, {
      message: 'daysLookback must be a positive integer',
    }),
})

export type GetKPIDataQueryType = z.infer<typeof GetKPIDataQuery>

export const GetKPIDataResult = z.array(
  z.object({
    activeDex: z.number(),
    activeCex: z.number(),
    avg24hVolume: z.number().nullable(),
    avgDepth: z.number().nullable(),
    spread: z.number().nullable(),
  }),
)
