<template>
  <div
    class="border-t border-forgd-gray-300 py-4 px-6 flex justify-between gap-2 items-center"
  >
    <div class="flex items-center justify-between w-full">
      <p class="text-xs font-normal text-forgd-primary-900">
        &copy; {{ (new Date).getFullYear() }} Forgd. All rights reserved.
        <NuxtLink to="/terms-of-use/" class="font-semibold text-forgd-primary-300">
          Terms & Conditions
        </NuxtLink>
      </p>

      <div class="text-forgd-gray-500 text-xs">
        The content on this site is for informational purposes only and should not be construed as financial or legal advice.
      </div>
    </div>
  </div>
</template>
