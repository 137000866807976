import { initContract } from '@ts-rest/core'
import { z } from 'zod'

const c = initContract()

export const TracingEvent = z.object({
  projectId: z.string().uuid().optional(),
  eventType: z.string(),
  eventData: z.record(z.string(), z.any()),
})

export type TracingEventType = z.output<typeof TracingEvent>

export const tracingContract = c.router({
  events: {
    method: 'POST',
    path: '/events',
    body: z.array(TracingEvent).min(1).max(10),
    responses: {
      204: z.undefined(),
    },
    summary: 'Report a batch of events into the tracing system (min 1, max 10)',
    metadata: {
      openApiSecurity: [], // public endpoint
    },
  },
})

export type TracingContractType = typeof tracingContract
