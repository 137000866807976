export default defineNuxtPlugin({
  setup(nuxtApp) {
    const teammateAccessWall = useTeammateAccessWall()
    const auth = useAuth()
    const router = useRouter()
    // blocks nav click
    router.beforeEach((to) => {
      if (to.path === '/partner-activity-lead/amm-2' && !auth.isOrganizationOwner) {
        teammateAccessWall.show()
        return false
      }
    })
    nuxtApp.hooks.hook('forgd:fetch:unauthorized', async () => {
      if (!auth.isOrganizationOwner) {
        teammateAccessWall.show()
      }
    })
  },
})
