import type { EffectScope } from 'vue'
import { useDebounceFn, useMouseInElement } from '@vueuse/core'

export interface NavLink {
  icon?: IconClass
  to?: string
  label?: string
  key?: string
  external?: boolean
  loading?: boolean
  testId?: string
  children?: NavLink[]
  isStrict?: boolean
  on?: Record<string, any>
}

export type SlideoverMenuId = 'tools-and-services' | 'forgd-academy' | string | null
const slideoverMenu = ref<SlideoverMenuId>(null)

const expanded = ref(false)
export function useNavState() {
  return {
    slideoverMenu,
    expanded,
  }
}

const SLIDEOVER_SWITCH_DELAY_MS = 250
let slideoverScope: EffectScope | null = null
export const closeSlideoverOnMouseover = {
  mouseenter: event => handleSlideoverChange({ event, slideover: null }),
}
export function handleSlideoverChange(options: { event: any, slideover: SlideoverMenuId }) {
  const { slideoverMenu } = useNavState()
  // closing already closed menu
  if (!options.slideover && !slideoverMenu.value) {
    return
  }

  const open = () => {
    slideoverMenu.value = options.slideover
    nextTick(() => {
      if (slideoverScope) {
        slideoverScope.stop()
      }
      slideoverScope = effectScope()
      slideoverScope!.run(() => {
        const { isOutside: isOutsideMenuItem } = useMouseInElement(options.event.target.parentElement)
        const { isOutside: isOutsideSlideover } = useMouseInElement(
          document.querySelector('[data-el="AppMenuSlideover"]')!,
        )
        watch(isOutsideMenuItem, (outside) => {
          debounceSlideoverFn(() => {
            if (isOutsideSlideover.value) {
              if (outside) {
                close()
              }
              else {
                open()
              }
            }
          })
        })
        let hasEnteredSlideover = false
        watch(isOutsideSlideover, (outside) => {
          if (!outside) {
            hasEnteredSlideover = true
          }
          if (!hasEnteredSlideover) {
            return
          }
          debounceSlideoverFn(() => {
            if (hasEnteredSlideover && outside) {
              close()
            }
            else {
              open()
            }
          })
        })
      })
    })
  }
  const close = () => {
    if (slideoverScope) {
      slideoverScope.stop()
    }
    slideoverMenu.value = null
  }
  // opening menu for the first time
  if (options.slideover && !slideoverMenu.value) {
    // switch immediately
    open()
    return
  }
  // queuedSlideoverMenu.value = options.slideover
  if (!options.event) {
    // we require an event if we're switching or closing
    return
  }

  debounceSlideoverFn(options.slideover ? open : close)
}

// @ts-expect-error broken upstream types
const debounceSlideoverFn = useDebounceFn<() => void>(fn => fn(), SLIDEOVER_SWITCH_DELAY_MS) as (
  fn: () => void,
) => void

export function firstChildToIfDir(item: NavLink) {
  if (item._partial && item.children?.[0]) {
    return item.children[0]?._path
  }
  return item._path
}
