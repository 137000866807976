import { z } from 'zod'
import { stringToIntNumber } from '@forgd/shared/utils/zod'

export const GetLiquidityBreakdownsByExchangeQuery = z.object({
  date: z.string().date(),
  marketMakerId: z.string().nullish(),
  orderBook: z.string().nullish(),
  daysLookback: z
    .string()
    .optional()
    .nullable()
    .transform(stringToIntNumber)
    .refine((val) => val == null || val > 0, {
      message: 'daysLookback must be a positive integer',
    }),
})

export const GetLiquidityBreakdownsQuery = GetLiquidityBreakdownsByExchangeQuery.extend({
  exchange: z.string().nullish(),
})

export type GetLiquidityBreakdownsQueryType = z.infer<typeof GetLiquidityBreakdownsQuery>
export type GetLiquidityBreakdownsByExchangeQueryType = z.infer<typeof GetLiquidityBreakdownsQuery>

const GetLiquidityBreakdownsByExchangeEntry = z.object({
  exchange: z.string(),
  ask100: z.number(),
  ask100Percentage: z.number(),
  ask200: z.number(),
  ask200Percentage: z.number(),
  ask50: z.number(),
  ask50Percentage: z.number(),
  bid100: z.number(),
  bid100Percentage: z.number(),
  bid200: z.number(),
  bid200Percentage: z.number(),
  bid50: z.number(),
  bid50Percentage: z.number(),
  bidAsk100: z.number(),
  bidAsk100Percentage: z.number(),
  bidAsk200: z.number(),
  bidAsk200Percentage: z.number(),
  bidAsk50: z.number(),
  bidAsk50Percentage: z.number(),
})

const GetLiquidityBreakdownsEntry = GetLiquidityBreakdownsByExchangeEntry.omit({
  exchange: true,
}).extend({
  allOthersAsk100: z.number(),
  allOthersAsk100Percentage: z.number(),
  allOthersAsk200: z.number(),
  allOthersAsk200Percentage: z.number(),
  allOthersAsk50: z.number(),
  allOthersAsk50Percentage: z.number(),
  allOthersBid100: z.number(),
  allOthersBid100Percentage: z.number(),
  allOthersBid200: z.number(),
  allOthersBid200Percentage: z.number(),
  allOthersBid50: z.number(),
  allOthersBid50Percentage: z.number(),
  allOthersBidAsk100: z.number(),
  allOthersBidAsk100Percentage: z.number(),
  allOthersBidAsk200: z.number(),
  allOthersBidAsk200Percentage: z.number(),
  allOthersBidAsk50: z.number(),
  allOthersBidAsk50Percentage: z.number(),
  overreportingAsk200: z.number(),
  overreportingBid200: z.number(),
  overreportingBidAsk200: z.number(),
})

export const GetLiquidityBreakdownsResult = z.array(GetLiquidityBreakdownsEntry)
export const GetLiquidityBreakdownsByExchangeResult = z.array(GetLiquidityBreakdownsByExchangeEntry)
