import { z } from 'zod'
import { GetLiquidityBreakdownsByExchangeResult } from './liquidity-breakdowns'
import { GetVolumeBreakdownsByExchangeResult } from './volume-breakdowns'
import { EngagementOptionArray } from '@forgd/supabase'

export const GetEngagementDetailsResult = z
  .object({
    type: z.enum(EngagementOptionArray),
    totalDaysTracked: z.number(),
    activeCex: z.number(),
    activeDex: z.number(),
    avgFillVolume: z.number(),
    avgDepth: z.number(),
    avgSpread: z.number(),
    avgVolumeBasedLoanUtil: z.number(),
    avgLiquidityBasedLoanUtil: z.number(),
    percentageOfTotalVolume: z.number(),
    percentageOfTotalLiquidity: z.number(),
    totalLiquidity: z.number(),
    totalVolume: z.number(),
    loanSize: z.number(),
    liquidityBreakdownsByExchange: GetLiquidityBreakdownsByExchangeResult,
    volumeBreakdownsByExchange: GetVolumeBreakdownsByExchangeResult,
  })
  .nullish()

export type GetEngagementDetailsResultType = z.infer<typeof GetEngagementDetailsResult>
