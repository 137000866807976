import referenceProjects from './projects.json'
import type { MarketMakerRFQOption } from '../types/market-maker-rfq'
import { EngagementOptionEnum } from '@forgd/supabase'

export const MAX_SELECTED_QUOTES = 5 // max number of quotes a user can select for requote or acceptance
export const MAX_QUOTES_SUBMITTED_PER_MARKET_MAKER_PER_ROUND = 3
export const MAX_DAYS_FOR_MARKET_MAKER_TO_SUBMIT_QUOTES = 7
export const MAX_DAYS_FOR_USER_TO_MAKE_DECISION_ON_QUOTES = 14

export const MMRFQExternalCapitalOptions: MarketMakerRFQOption[] = [
  { value: 'no_capital', label: 'No capital raised to date' },
  { value: '100K-500K', label: '$100,000 - $500,000' },
  { value: '500K-1000K', label: '$500,000 - $1,000,000' },
  { value: '1000K-2500K', label: '$1,000,000 - $2,500,000' },
  { value: '2500K-5000K', label: '$2,500,000 - $5,000,000' },
  { value: '5000K-10000K', label: '$5,000,000 - $10,000,000' },
  { value: 'more_than_10000K', label: 'More than $10,000,000' },
]

export const MMRFQMotivationsOptions: MarketMakerRFQOption[] = [
  {
    value: 'price_performance',
    label: 'Price Performance',
    description: '“I want to achieve the highest possible valuation (FDV).”',
  },
  {
    value: 'user_acquisition',
    label: 'User Acquisition',
    description: '“I want to acquire as many users as possible at a product / protocol level.”',
  },
  {
    value: 'treasury_management',
    label: 'Treasury Management',
    description: '“I want to liquidate tokens to increase its stablecoin reserves and extend the operational runway.”',
  },
  {
    value: 'liquidity_improvement',
    label: 'Liquidity Improvement',
    description: '“I want to ensure robust liquidity to facilitate efficient price discovery for our native token.”',
  },
]

export const MMRFQEngagementOptions: MarketMakerRFQOption[] = [
  {
    value: EngagementOptionEnum.LoanCallOption,
    label: 'Loan + Call Option',
    description:
      'Receives a loan of tokens with the option (but not obligation) to purchase tokens at some future date. Adheres to liquidity KPIs per legal contract.',
    loanRequirements: ['Tokens'],
  },
  {
    value: EngagementOptionEnum.RetainerWorkingCapital,
    label: 'Retainer + Working Capital',
    description:
      'Charges monthly fee to operate as a strategic liquidity provider. Trades using your capital (tokens & stablecoins). May receive a profit share.',
    loanRequirements: ['Tokens', 'Stablecoins'],
  },
]

export const MMRFQTokenSupplyPercentageToAllocateOptions: MarketMakerRFQOption[] = [
  { value: '0.10-0.25', label: '0.10% - 0.25%' },
  { value: '0.25-0.50', label: '0.25% - 0.50%' },
  { value: '0.50-1.00', label: '0.50% - 1.00%' },
  { value: '1.00-3.00', label: '1.00% - 3.00%' },
  { value: '3.00-5.00', label: '3.00% - 5.00%' },
  { value: 'more_than_5.00', label: 'More than 5.00%' },
]

export const MMRFQStableCoinBudgetToAllocateOptions: MarketMakerRFQOption[] = [
  { value: '50K-100K', label: '$50K - $100K' },
  { value: '100K-150K', label: '$100K - $150K' },
  { value: '150K-250K', label: '$150K - $250K' },
  { value: '250K-500K', label: '$250K - $500K' },
  { value: 'more_than_500K', label: 'More than $500K' },
  { value: 'unknown', label: "I don't know" },
]

export const MMRFQKpiTooltips = {
  '% of Quotes with Fees Charged per Exchange':
    'The average size of the combined token & stablecoin loan, in US Dollar terms.',
  '% of Quotes with Unlimited Exchange Coverage': '',
  'Avg. Order Book Dominance, Max %': '',
  '% of Options Priced Within >6 Month Post-Inception':
    'On average, what percentage of tranches with a dynamic strike price will be priced after 6 months of the engagement commencing?',
  '% of Options Priced Within 1-6 Month Post-Inception':
    'On average, what percentage of tranches with a dynamic strike price will be priced within 1 - 6 months of the engagement commencing?',
  '% of Options Priced Within <1 Month Post-Inception':
    'On average, what percentage of tranches with a dynamic strike price will be priced within the first 1 month of the engagement commencing?',
  '% of Tranches Dynamic Price':
    'On average, what percentage of tranches within each loan carry a strike price that is “dynamic”? (Note: dynamic strike prices are calculated using a TWAP whereas fixed strike prices are denominated as a % relative to the spot price.)',
  '% of Tranches Fixed Price':
    'On average, what percentage of tranches within each loan carry a strike price that is “fixed”? (Note: fixed strike prices are denominated as a % relative to the spot price whereas dynamic strike prices are calculated using a TWAP.)',
  'Avg. Number of Tranches':
    'The average number of tranches per loan requested by Market Makers in this round of the RFQ.',
  'Avg. Interest Rate (APR, %)':
    'The average interest rate (offered to the project in exchange for the token loan) provided by Market Makers in this round of the RFQ.',
  'Avg. Token Loan Size (% of Total Supply)':
    'The average size of the loan requested by Market Makers in this round of the RFQ, denominated as a % of the total token supply.',
  'Number of European Options':
    'European options restrict the Market Maker and dictate they only have the right to exercise the option at contract expiration, at the agreed upon strike price.',
  'Number of American Options':
    'American options give the Market Maker a high degree of flexibility and offer them the right to exercise the option at any date during their loan contract, at the agreed upon strike price.',
  'Avg. Loan Tenor (Months)': `Loan tenor is the length of time remaining on a loan, or the amount of time until the loan is due. It's also known as the time-to-maturity of a loan.`,
  'Avg. Token Loan Size (USD)': 'The average size of the token loan, in US Dollar terms.',
  'Avg. Option Premium, per Quote':
    'The average premium of the strike prices at which each market maker has the option (but not obligation) to purchase tokens. Note, the average is value is taken per proposal, with each proposal potentially containing multiple strike prices depending on the number of tranches.',
  'Number of Quotes': 'Number of proposals received',
  'Avg. Token Loan Size (%)': `The average size of the token loan, as a % of your Token Supply.`,
  'Avg. Token Loan Size (Qty.)': `The average size of the token loan, as quantity of tokens.`,
  'Avg. Stablecoin Loan Size (USD)': 'The average size of the stablecoin loan, in US Dollar terms.',
  'Avg. Total Loan Size (USD)': 'The average size of the combined token & stablecoin loan, in US Dollar terms.',
  'Avg. Onboarding Fee (USD)':
    'The average fee charged by the market makers to configure strategies and begin coverage for your project, in US Dollar terms.',
  'Avg. Monthly Fee (USD)':
    'The monthly average fee charged by the market makers to maintain coverage for your project, in US Dollar terms. Note, if the market maker charges on a per exchange basis, Forgd assumes coverage on four exchanges when calculating their estimated monthly fee.',
  'Avg. Profit Share (%)':
    'The average percentage of total profit that will be allocated to market makers if they can successfully grow the value of your loaned capital. Note, market makers calculate profit in a variety of ways.',
}

export { referenceProjects }
