import { z } from 'zod'
import { TProject } from '../projects'
import { BaseLayerTypeArray, MemberStatusArray, SectorTypeArray } from '@forgd/supabase'
import { datetimeLocal } from '@forgd/shared/utils/zod'

export const UserResponse = z.object({
  id: z.string(),
  email: z.string(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  isOnboarded: z.boolean(),
  telegram: z.string().nullable(),
  createdAt: datetimeLocal,
  updatedAt: datetimeLocal,
  deletedAt: datetimeLocal.nullable(),
  isGuidedTourEnabled: z.boolean(),
  organizations: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
      ownerUserId: z.string(),
      createdAt: datetimeLocal,
      updatedAt: datetimeLocal,
      deletedAt: datetimeLocal.nullable(),
    }),
  ),
})

const GetMeProject = TProject.omit({
  areasOfInterest: true,
}).extend({
  organizationId: z.string().uuid().nullable(),
  createdAt: datetimeLocal,
  updatedAt: datetimeLocal,
  deletedAt: datetimeLocal.nullable(),
  tgeDate: z.string().date().nullable(),
  baseLayer: z.array(z.enum(BaseLayerTypeArray)).nullable(),
  sector: z.array(z.enum(SectorTypeArray)).nullable(),
  isOnboarded: z.boolean(),
})

const GetMeOrganization = z.object({
  id: z.string(),
  name: z.string(),
  ownerUserId: z.string(),
  createdAt: datetimeLocal,
  updatedAt: datetimeLocal,
  deletedAt: datetimeLocal.nullable(),
  projects: z.array(GetMeProject),
  membersCount: z.number(),
  memberStatus: z.enum(MemberStatusArray),
})

export const GetMeResponse = z.object({
  id: z.string(),
  email: z.string(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  isOnboarded: z.boolean(),
  telegram: z.string().nullable(),
  createdAt: datetimeLocal,
  updatedAt: datetimeLocal,
  deletedAt: datetimeLocal.nullable(),
  isGuidedTourEnabled: z.boolean(),
  organizations: z.array(GetMeOrganization),
})

export type UserMeResponse = z.infer<typeof GetMeResponse>
export type OrganizationMeResponse = z.infer<typeof GetMeOrganization>
export type ProjectMeResponse = z.infer<typeof GetMeProject>
